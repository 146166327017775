import React from "react";

interface Map {
  [key: string]: string;
}

const BUTTON_TEXT: Map = {
  calendar: "+\xa0Add\xa0Dates",
  default: "+\xa0Add",
};

interface IAddButton {
  variant?: string;
}

const addButton = ({ variant }: IAddButton): JSX.Element => {
  return (
    <span className="h-[28px] w-fit rounded-full border-[2px] border-dashed border-darkblue px-2 pr-2 text-p13 text-darkblue">
      {variant ? BUTTON_TEXT[variant] : BUTTON_TEXT.default}
    </span>
  );
};

export default addButton;
